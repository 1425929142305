<template>
  <div>
    
    <div class="row bg-light pt-2 pb-2 m-1">
      <div class="col-md-6">
        <button :disabled="btnDisabled == 2" @click="getChecklistItems(2, customerProduct.product_id)" type="button" class="btn btn-sm btn-primary btn-block"> {{ btnDisabled == 2 ? "Please wait..." : "Developer Checkoff Sheet" }}</button>
      </div>
      <div class="col-md-6">
        <strong class="float-right mt-1" :class="devStatusClass">
          {{ devStatus }} 
          <small v-if="customerProduct.selected_developer_checkoff_count > 0">
            ({{customerProduct.selected_developer_checkoff_count}} / {{ customerProduct.developer_checkoff_count }})
          </small>
        </strong>
      </div>
    </div>
    
    <div class="row bg-light pt-2 pb-2 m-1">
      <div class="col-md-6">
        <button :disabled="btnDisabled == 1" @click="getChecklistItems(1, customerProduct.product_id)" type="button" class="btn btn-sm btn-primary btn-block">{{ btnDisabled == 1 ? "Please wait..." : "Staff Onboarding Checkoff Sheet" }}</button>
      </div>
      <div class="col-md-6">
        <strong class="float-right mt-1" :class="staffStatusClass">
          {{ staffStatus }} 
          <small v-if="customerProduct.selected_staff_checkoff_count > 0">
            ({{customerProduct.selected_staff_checkoff_count}} / {{ staff_checkoff_list_count }})
          </small>
        </strong>
      </div>
    </div>

    <!-- Checkout List Modal -->
    <Dialog :maximizable="true" :draggable="false" modal :closeOnEscape="true" v-model:visible="showCheckOffModal" :style="{ width: '45vw' }" @hide="resetDialog(0)" >
      <template #header>
        {{ checkoffListType == 1 ? "Staff Onboarding Checkoff Sheet" : "Developer Checkoff Sheet" }}
      </template>
      <template #default>
        <p class="text-primary" v-if="developer_name"><i class="fa fa-user ml-1" aria-hidden="true"></i> Developer: {{ developer_name }}</p>
        <form @submit.prevent="formSubmit">
          <table class="table table-hover">
            <thead class="bg-secondary">
              <tr class="text-white">
                <th scope="col">List Items</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="listItem in checklistsData" :key="listItem.id" @click="toggleSelection(listItem.id)">
                <td>{{ listItem.title }}</td>
                <td class="p-0 text-center">
                  <Checkbox class="mt-2" v-model="selected_checklist_items" :inputId="listItem.id" :value="listItem.id" @click.stop="toggleSelection(listItem.id)" />
                </td>
              </tr>
              <tr v-if="checklistsData.length === 0">
                <td colspan="2">
                  <br /><strong class="text-danger">No checkoff list item found.</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </template>
      <template #footer>
        <strong class="text-secondary float-left">
          Total Selected Items: <span :class="totalClass">{{ selected_checklist_items.length }} / {{ checklistsData.length }}</span> 
          <small class="ml-2 badge badge-pill badge-warning" v-if="totalClass == 'text-danger'">Pending</small>
          <small class="ml-2 badge badge-pill badge-primary" v-if="totalClass == 'text-info'">In-progress</small>
          <small class="ml-2 badge badge-pill badge-success" v-if="totalClass == 'text-success'">Completed</small>
        </strong> 
        <button class="btn btn-primary" :disabled="checklistsData.length == 0 || loading" type="button" @click="formSubmit" > {{ loading ? 'Saving...' : 'Save' }}</button>
        <button class="btn btn-danger" type="button" @click="resetDialog(0)" > Close </button>
      </template>
    </Dialog>
    <!-- / Checkout List Modal -->
  </div>
</template>
<script>
import axios from "axios";
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';

export default {
  props: [
    'customerProduct',
    'staffStatus',
    'devStatus',
    'staff_checkoff_list_count'
  ],

  components: { Dialog, Checkbox },

  data() {
    return {
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      selected_checklist_items: [],
      btnDisabled: 0,
      loading: false,
      checklistsData: null,
      developer_name: null,
      checkoffListType: null,
      showCheckOffModal: false,
      listStatus: 'Pending',
    };
  },

  computed: {
    staffStatusClass() {
      if (this.staffStatus === 'Completed') {
        return 'text-success';
      } else if (this.staffStatus === 'In-progress') {
        return 'text-primary';
      } else if (this.staffStatus === 'Pending') {
        return 'text-warning';
      }
      return ''; // Default class if no conditions match
    },

    // Rename this computed property to avoid duplication
    devStatusClass() { 
      if (this.devStatus === 'Completed') {
        return 'text-success';
      } else if (this.devStatus === 'In-progress') {
        return 'text-primary';
      } else if (this.devStatus === 'Pending') {
        return 'text-warning';
      }
      return ''; // Default class if no conditions match
    },
    
    totalClass() { 
      if (this.selected_checklist_items.length === this.checklistsData.length && this.checklistsData.length > 0) {
        return 'text-success';
      } else if (this.selected_checklist_items.length > 0 && this.selected_checklist_items.length != this.checklistsData.length) {
        return 'text-info';
      } else if (this.selected_checklist_items.length == 0) {
        return 'text-danger';
      }
      return ''; // Default class if no conditions match
    },
  },

  methods: {
    toggleSelection(itemId) {
      const index = this.selected_checklist_items.indexOf(itemId);
      if (index === -1) {
        // Add item if not already selected
        this.selected_checklist_items.push(itemId);
      } else {
        // Remove item if already selected
        this.selected_checklist_items.splice(index, 1);
      }
    },
    getChecklistItems(type, productId) {
      const data = {
        user_product_id: this.customerProduct.id,
        list_type: type,
        product_id: productId
      };

      this.btnDisabled = type;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
        .post(this.env_api_url + "/get-checkoff-list" , data, { headers })
        .then((response) => {
          this.checklistsData = response.data.checklists;
          this.selected_checklist_items = response.data.product_selected_checklist_array;
          this.developer_name = response.data.developer_name;
          this.checkoffListType = type;
          this.btnDisabled = 0;
          this.showCheckOffModal = true;
        })
        .catch((error) => {
          this.btnDisabled = 0;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },
    
    formSubmit() {
      this.loading = true;
      const data = {
        user_product_id: this.customerProduct.id,
        selected_items: this.selected_checklist_items,
        list_type: this.checkoffListType,
      };

      const headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
      .post(this.env_api_url + "/submit_checklist", data, {
        headers,
      })
      .then((response) => {
        console.log("Checklist submitted successfully!", response);
        this.$notify({
          type: "success",
          title: "Success",
          text: "Checklist saved successfully!",
        });
        this.resetDialog(1);
      })
      .catch((error) => {
        console.error("Error submitting checklist:", error);
        this.loading = false;
        this.$notify({
          type: "error",
          title: "Error",
          text: "Something went wrong!",
        });
      });
    },

    resetDialog(isFormSubmit) {
      this.loading = false;
      this.showCheckOffModal = false;
      this.selected_checklist_items = [];
      if (isFormSubmit == 1) {
        this.$emit('refreshCustomerApi');
      }
    },
  },
};
</script>

<style scoped>
  .table-hover tbody tr {
    cursor: pointer; /* Cursor will change to hand pointer */
  }
</style>