<template>
  <div>
    <form @submit.prevent="formSubmit" class="user">
      <div class="form-group row">
        <div class="col-md-8">
          <label class="required" for="exampleSiteName"> Driver</label>
          <div class="">
            <select v-model="mailDriver" class="form-control form-control-sm" >
              <option selected :value="undefined">--Select Mail Driver--</option>
              <option value="smtp">SMTP</option>
              <option value="mailgun">Mailgun (API)</option>
              <option value="rezo-mailing">Rezo mailing</option>
              <option value="log_developer">Log Developer SendMailing</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="mailDriver === 'mailgun'">
        <div class="row m-0">
          <div class="col-md-5" style="padding: 0px;">
            <div class="col-md-12 mt-2">
              <label> Config Domain</label>
              <input
                v-model="configDomain"
                type="text"
                class="form-control form-control-sm"
                id="exampleConfigDomain"
              />
            </div>
            <div class="col-sm-12">
              <label> Config Key</label>
              <input v-model="configKey" type="password" id="exampleconfigKey" class="form-control mb-2 form-control-sm"/>
            </div>
          </div>

          <div class="col-md-7" v-show="WellDisplay">
            <div class="row customerCard">
                <div class="col-md-12">
                  <!-- <p>{{configDomain_value}}</p> -->
                  <button class="btn btn-info btn-sm float-right" type="button" @click="editFields(configDomain_value)">Edit</button>
                  <button class="btn btn-light btn-sm disabled" type="button">{{configDomain_value}}</button>
                </div>
                <div class="col-sm-12" style="padding-top: 8px;">
                  <!-- <p style="-webkit-text-security': disc" > {{configKey_value}}</p> -->
                  <button class="btn btn-light btn-sm disabled" type="button">{{configKey_value}}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" v-if="mailDriver === 'smtp'">
        <div class="col-md-6 mb-sm-0">
          <label for="exampleHost"> Host</label>
          <input
            v-model="smtpHost"
            type="text"
            class="form-control form-control-sm"
            id="exampleHost"
          />
        </div>
        <div class="col-md-6  mb-sm-0">
          <label for="examplePort"> Port</label>
          <input
            v-model="smtpPort"
            type="text"
            class="form-control form-control-sm"
            id="examplePort"
          />
        </div>
        <div class="col-md-6 mt-3 mb-sm-0">
          <label for="exampleUserName"> Username</label>
          <input
            v-model="smtpUserName"
            type="text"
            class="form-control form-control-sm"
            id="exampleUserName"
          />
        </div>
          <div class="col-sm-6 mt-3">
        <label for=""> Password</label>
        <input v-model="smtpPassword" type="password" id="examplepassword" class="form-control form-control-sm"/>
      </div>
    </div>
    <div class="form-group row" v-if="mailDriver === 'rezo-mailing'">
      <div class="col-md-8 mb-sm-0">
        <label for="exampleHost">Rezo Mailing Token</label>
        <input
          v-model="rezo_mailing_token"
          type="text"
          placeholder="**** (Enter new token to update)"
          autocapitalize="off"
          class="form-control form-control-sm masked"
          id="exampleHost"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6 mb-sm-0">
        <label class="required" for="examplefromEmail"> From (email)</label>
        <input
          v-model="fromEmail"
          type="email"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.fromEmail.$error }"
          id="examplefromEmail"
        />
        <span class="text-danger" v-if="v$.fromEmail.$error">
          <span
            class="help-block"
            v-for="validationError in v$.fromEmail.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
        <div class="col-md-6 mb-sm-0">
          <label class="required" for="examplefromName"> From (name)</label>
          <input
            v-model="fromName"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': v$.fromName.$error }"
            id="examplefromName"
          />
          <span class="text-danger" v-if="v$.fromName.$error">
            <span
              class="help-block"
              v-for="validationError in v$.fromName.$errors"
              :key="validationError"
            >
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    </form>
  </div>
</template>

<script>
  import useValidate from "@vuelidate/core";
  import { required, minLength, maxLength, email } from "@vuelidate/validators";
  import axios from "axios";
  export default {
    data() {
      return {
        v$: useValidate(),
        mailDriver: "",
        configDomain: null,
        configKey: null,
        configDomain_value: 'N/A',
        configKey_value: 'N/A',
        show_configKey: null,
        WellDisplay: false,
        smtpHost: null,
        rezo_mailing_token: null,
        smtpPort: null,
        smtpUserName: null,
        smtpPassword: null,
        fromName: null,
        fromEmail: null,
        isDisabled: false,
        btnTitle: "Update Settings",
        base_url: process.env.VUE_APP_SERVICE_URL,

      };
    },

    validations() {
      return {
        fromName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(150),
        },
        fromEmail: {
          required,
          email,
          minLength: minLength(3),
          maxLength: maxLength(150),
        },
      };
    },

    created() {
      var mailId = 3;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
      .get(this.base_url + "/settings/" + mailId, { headers })
      .then((response) => {
        this.mailDriver = response.data.mail_driver,
        this.configDomain_value = response.data.config_domain == null ? 'N/A': response.data.config_domain,
        this.show_configKey = response.data.config_key, 
        response.data.config_key == null ? this.WellDisplay = false: this.WellDisplay = true, //Condition also for CSS
        this.smtpHost= response.data.smtp_host,
        this.smtpPort= response.data.smtp_port,
        this.smtpUserName= response.data.smtp_user_name,
        this.smtpPassword= response.data.smtp_password,
        this.fromName = response.data.from_name,
        this.fromEmail = response.data.from_email

        let total_x  = ''
        for (let index = 1; index <= response.data.config_key.length; index++) {
          total_x = total_x + 'x';
        }

        this.configKey_value = total_x;
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    methods: {
      editFields(domain) {
        this.configDomain = domain;
        this.configKey = this.show_configKey;
      },


      formSubmit() {
        this.v$.$validate();
        if (this.v$.$error) {
          console.log("errors");
          //event.preventDefault();
        } else {
          this.isDisabled = true;
          this.btnTitle = "Waiting...";

          var data = {
            setting_id: 3,
            mail_driver: this.mailDriver,
            config_domain: this.configDomain,
            config_key: this.configKey,
            rezo_mailing_token: this.rezo_mailing_token,
            smtp_host: this.smtpHost,
            smtp_port: this.smtpPort,
            smtp_user_name: this.smtpUserName,
            smtp_password: this.smtpPassword,
            from_name: this.fromName,
            from_email: this.fromEmail
          };
          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_customers_user"),
          };

          axios.post(this.base_url + "/settings", data, { headers }).then(
            (response) => {
              if (response.status === 200) {
                this.btnTitle = "Update Settings";
                this.isDisabled = false;
                this.rezo_mailing_token = null;
                this.$notify({
                  type: "success",
                  title: "Success",
                  text: "Saved Successfully!",
                });
                console.log("Form submitted successfully");
                this.$router.push({ path: "/settings/mail" });
              }
              console.log(response);
            },
            (error) => {
              console.log(error.response.data.error);
              this.btnTitle = "Update Settings";
              this.isDisabled = false;
              this.$notify({
                type: "error",
                title: "Something went wrong!",
                text: error.response.data.error,
              });
            }
          );
        }
      },
    },
  }
</script>

<style scoped>
  .customerCard {
    background: #e7edff;
    border: 1px;
    border-color: #d1d3e2;
    border-style: solid;
    border-radius: 5px;
    margin-top: 41px;
    margin-right: -3px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  input.masked {
    -webkit-text-security: disc; /* This will display the characters as dots */
  }

</style>