import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Calendar from '../views/calendar.vue'
import Customers from "../views/Customers/Customers.vue"
import NewCustomer from "../views/Customers/Create.vue"
import EditCustomer from "../views/Customers/EditCustomer.vue"
import Leads from "../views/Leads/Leads.vue"
import NewLead from "../views/Leads/Create.vue"
import EditLead from "../views/Leads/EditLead.vue"
import Reports from '../views/Reports/Reports.vue'

import Surveys from "../views/Surveys/Surveys.vue"
import SurveyReport from "../views/Surveys/SurveyReport.vue"
import SurveySubmission from "../views/Surveys/SurveySubmission.vue"
import ThankYou from "../views/Surveys/ThankYou.vue"
import ChecklistThankYou from "../views/Surveys/ChecklistThankYou.vue"
import ShowReport from "../views/Surveys/ShowReport.vue"
import FilledSurvey from "../views/Surveys/FilledSurvey.vue"
import NewSurvey from "../views/Surveys/Create.vue"
import EditSurvey from "../views/Surveys/EditSurvey.vue"
import TestSurvey from "../views/Surveys/TestSurvey.vue"
import Test from "../views/Test.vue"

import Products from "../views/Products/Products.vue"
import NewProduct from "../views/Products/ProductForm.vue"
import EditProduct from "../views/Products/ProductForm.vue"

import SmsList from "../views/sms_tool/sms_list.vue"
import Admins from "../views/Admins/Admins.vue"
import NewAdmin from "../views/Admins/Create.vue"
import EditAdmin from "../views/Admins/EditAdmin.vue"

import Basket from '../views/Basket.vue'
import Servers from '../views/servers/Servers.vue'
import NewServer from "../views/servers/Create.vue"
import ServersEdit from '../views/servers/ServersEdit.vue'
import DemoModules from '../views/DemoModules/DemoModules.vue'
import NewDemoModule from "../views/DemoModules/Create.vue"
import EditDemoModule from '../views/DemoModules/EditDemoModule.vue'
import BusinessModules from '../views/BusinessModules/BusinessModules.vue'
import NewBusinessModule from "../views/BusinessModules/Create.vue"
import EditBusinessModule from '../views/BusinessModules/EditBusinessModule.vue'

import GeneralSetting from '../views/Settings/GeneralSetting.vue'
import SiteSetting from '../views/Settings/SiteSetting.vue'
import MailSetting from '../views/Settings/MailSetting.vue'
/* import GatewaySetting from '../views/Settings/GatewaySetting.vue' */
import TwilioSetting from '../views/Settings/TwilioSetting.vue'
import Checklist from '../views/checklists/Checklist.vue'
import ItemForm from '../views/checklists/ItemForm.vue'
import EditItemForm from '../views/checklists/EditItemForm.vue'
import DevChecklistForm from '../views/DevChecklistForm.vue'


const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { 
      auth: false ,
      layout: 'login'
    },
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: { auth: true },
    component: Dashboard
  },
// =============================================================================
//    Customers Routes
// =============================================================================
  
  {
    path:"/follow_up_calendar",
    name:"Calendar",
    meta: { auth: true },
    component:Calendar
  },

  {
    path:"/customers",
    name:"Customers",
    meta: { auth: true },
    component:Customers
  },
  {
    path:"/customers/create",
    name:"NewCustomer",
    meta: { auth: true },
    component:NewCustomer
  },
  {
    path:"/customers/:id/edit",
    name:"EditCustomer",
    meta: { auth: true },
    component:EditCustomer
  },

  {
    path: '/basket',
    name: 'Basket',
    meta: { auth: true },
    component: Basket
  },
// =============================================================================
//    Servers Routes
// =============================================================================
  
{
  path:"/servers",
  name:"Servers",
  meta: { auth: true },
  component:Servers
},
{
  path:"/servers/create",
  name:"NewServer",
  meta: { auth: true },
  component:NewServer
},
{
  path:"/servers/:id/edit",
  name:"EditServer",
  meta: { auth: true },
  component:ServersEdit
},

// =============================================================================
//    Rezo Modules Routes
// =============================================================================
  
{
  path:"/demo_modules",
  name:"DemoModules",
  meta: { auth: true },
  component:DemoModules
},
{
  path:"/demo_modules/create",
  name:"NewDemoModule",
  meta: { auth: true },
  component:NewDemoModule
},
{
  path:"/demo_modules/:id/edit",
  name:"EditDemoModule",
  meta: { auth: true },
  component:EditDemoModule
},
{
  path:"/business_modules",
  name:"BusinessModules",
  meta: { auth: true },
  component:BusinessModules
},
{
  path:"/business_modules/create",
  name:"NewBusinessModule",
  meta: { auth: true },
  component:NewBusinessModule,
},
{
  path:"/business_modules/:id/edit",
  name:"EditBusinessModule",
  meta: { auth: true },
  component:EditBusinessModule
},
  
// =============================================================================
//    Leads Routes
// =============================================================================
  {
    path:"/leads/:id/edit",
    name:"EditLead",
    meta: { auth: true },
    component:EditLead
  },
  {
    path:"/leads",
    name:"Leads",
    meta: { auth: true },
    component:Leads
  },
  {
    path:"/leads/create",
    name:"NewLead",
    meta: { auth: true },
    component:NewLead
  },

  // =============================================================================
//    Reports Routes
// =============================================================================
  {
    path:"/reports/customer-support-services",
    name:"Customer-Support-Services-Reports",
    meta: { auth: true },
    component:Reports
  },
// =============================================================================
//    Surveys Routes
// =============================================================================
{
  path:"/survey/reports",
  name:"SurveyReport",
  meta: { auth: true },
  component:SurveyReport
},
{
  path:"/survey/submissions",
  name:"SurveySubmission",
  meta: { auth: true },
  component:SurveySubmission
},
{
  path:"/survey/completed",
  name:"ThankYou",
  meta: { auth: false }, //OFF authorization because it's a public link!
  component:ThankYou
},
{
  path:"/checklist/submitted",
  name:"ChecklistThankYou",
  meta: { auth: false }, //OFF authorization because it's a public link!
  component:ChecklistThankYou
},
{
  path:"/survey/:id/report",
  name:"ShowReport",
  meta: { auth: true },
  component:ShowReport
},
{
  path:"/surveys",
  name:"Surveys",
  meta: { auth: true },
  component:Surveys
},
{
  path:"/survey/create",
  name:"NewSurvey",
  meta: { auth: true },
  component:NewSurvey
},
{
  path:"/survey/:id/edit",
  name:"EditSurvey",
  meta: { auth: true },
  component:EditSurvey
},
{
  path:"/survey/:id/test",
  name:"TestSurvey",
  meta: { auth: false }, //OFF authorization because it's a public link!
  component:TestSurvey
},
{
  path:"/survey/:id/rezocustomers", /// This URL use for Survey Submission
  name:"RezoCustomersFeedBack",
  meta: { auth: false }, //OFF authorization because it's a public link!
  component:TestSurvey
},
{
  path:"/testing", /// This URL use for Survey Submission
  name:"TestDeployment",
  component:Test
},
{
  path:"/survey/:id/filled", /// This URL use for Survey Submission
  name:"FilledSurvey",
  meta: { auth: true }, //OFF authorization because it's a public link!
  component:FilledSurvey
},

// =============================================================================
//    Checklist Routes
// =============================================================================
{
  path:"/checklist",
  name:"Checklist",
  meta: { auth: true },
  component:Checklist
},
{
  path:"/checklist/create",
  name:"NewItem",
  meta: { auth: true },
  component:ItemForm
},
{
  path:"/checklist/edit/:id",
  name:"EditItem",
  meta: { auth: true },
  component:EditItemForm
},
{
  path:"/checklist/:id/edit",
  name:"EditProduct",
  meta: { auth: true },
  component:EditProduct
},

// =============================================================================
//    Products Routes
// =============================================================================

{
  path:"/products",
  name:"Products",
  meta: { auth: true },
  component:Products
},
{
  path:"/product/create",
  name:"NewProduct",
  meta: { auth: true },
  component:NewProduct
},
{
  path:"/product/:id/edit",
  name:"EditProduct",
  meta: { auth: true },
  component:EditProduct
},

// =============================================================================
//    SMS TOOL Routes
// =============================================================================
{
  path:"/sms_list",
  name:"SmsList",
  meta: { auth: true },
  component:SmsList
},

// =============================================================================
//    Admin Routes
// =============================================================================
  
{
  path:"/admins",
  name:"Admins",
  meta: { auth: true },
  component:Admins
},
{
  path:"/admins/create",
  name:"NewAdmin",
  meta: { auth: true },
  component:NewAdmin
},
{
  path:"/admins/:id/edit",
  name:"EditAdmin",
  meta: { auth: true },
  component:EditAdmin
},
// =============================================================================
//    Settings Routes
// =============================================================================
{
  path:"/settings/general",
  name:"GeneralSetting",
  meta: { auth: true },
  component:GeneralSetting
},
{
  path:"/settings/site",
  name:"SiteSetting",
  meta: { auth: true },
  component:SiteSetting
},
{
  path:"/settings/mail",
  name:"MailSetting",
  meta: { auth: true },
  component:MailSetting
},
/* {
  path:"/settings/gateway",
  name:"GatewaySetting",
  meta: { auth: true },
  component:GatewaySetting
}, */
{
  path:"/settings/twilio",
  name:"TwilioSetting",
  meta: { auth: true },
  component:TwilioSetting
},
{
  path:"/dev-checklist-form",
  name:"DevChecklistForm",
  meta: { auth: false }, //OFF authorization because it's a public link!
  component:DevChecklistForm
},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  var isloggedin= localStorage.getItem("rezo_customers_user");
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isloggedin) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => {
  const isloggedin = localStorage.getItem('rezo_customers_user'); // Check if token exists

  if (to.meta.auth && !isloggedin) {
    next('/login'); // Redirect to login page
  } else {
    next(); // Allow navigation
  }
});

export default router
