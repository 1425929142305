<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">Customer Servers</h4>
            </div>
            <div class="col-md-6">
              <Button type="button" class="btn btn-info btn-sm float-right" @click="historyModel">Emails History</Button>
              <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="historyDisplay" :style="{width: '80vw'}">
                  <template #header>
                    <h4>Sent Emails History</h4>
                  </template>
                  <DataTable :value="emailsHistory" :paginator="true" style="padding:4px;" :rows="10" selectionMode="single"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Column style="padding:4px;" field="from" header="From"></Column>
                    <Column style="padding:4px;" field="name" header="Name"></Column>
                    <Column style="padding:4px;" field="subject" header="Subject"></Column>
                    <Column style="padding:4px;" field="to" header="To"></Column>
                    <Column style="padding:4px;" field="created_at" header="Date">
                      <template #body="slotDate">
                        {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
                      </template>
                    </Column>
                  </DataTable>
              </Dialog>
            </div>
          </div>

          <p class="card-text"></p>
          <div class="d-flex flex-column justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px; margin-top:1px;" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s" />
          </div>
          <div class="row" v-else>
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="customerServer in customerServers" :key="customerServer">
                <div class="card-body">
                  <p class="card-title">
                    <!-- Server Name: {{ customerServer.domain_name }}  -->
                    Server Name: {{ customerServer.server.name }}
                    <span class="float-right">
                      <button @click="editCustomerServer(customerServer)" class="btn btn-primary btn-sm mr-1">Edit</button>
                      <button class="btn btn-danger btn-sm mr-1" @click="deleteCustomerServer(customerServer)">Delete</button>

                      <Button type="button" class="btn btn-warning btn-sm" label="Toggle" v-on:click="showDialog(customerServer.id)">Email</Button>
                      <Dialog :maximizable="true" :modal="true" :draggable="false" :closeOnEscape="true" v-model:visible="display" :style="{width: '25vw'}">
                          <template #header>
                            <h5>Send Mail</h5>
                          </template>
                        <sendEmail :customerID="customer_id" :serverID="server_id" :model="display" :customerProducts="customerProducts" @Display="dialog($event)" />
                      </Dialog>

                    </span>
                  </p>
                  <p class="card-text">
                    IP Address: <small> {{ customerServer.ip_address }}</small><br>
                    Domain Name: <small> {{ customerServer.domain_name }}</small><a :href="customerServer.domain_name" target="_blank" class="btn btn-info btn-sm mt-1 ml-2" style="height: 27px; padding: 2px;">Open</a><br>
                    Repository link: <small> {{ customerServer.repo }}</small><a :href="customerServer.repo" target="_blank" class="btn btn-info btn-sm mt-1 ml-2" style="height: 27px; padding: 2px;">Open</a><br>
                    Document Root: <small> {{ customerServer.doc_root }}</small><br>
                    Database Name: <small> {{ customerServer.db_name }}</small><br>
                    Database User: <small> {{ customerServer.db_user }}</small><br>
                    Database Password: <small v-bind:class="{text_As_Pswrd: disc_text}" id="db_password" ref="db_password"> {{ customerServer.db_password }}</small>
                    <i v-on:click="showPasword" id="eyeIcon" class="fa fa-eye zoom ml-2" style="cursor: pointer;" aria-hidden="true"></i><br>
                    Phpmyadmin: <small> {{ customerServer.php_my_admin }}</small><a :href="customerServer.php_my_admin" target="_blank" class="btn btn-info mt-1 btn-sm ml-2" style="height: 27px; padding: 2px;">Open</a><br>
                    Notes: <small> {{ customerServer.notes }}</small> 
                  </p>
                </div>
              </div>
              <p class="text-danger text-center" v-if="customerServers.length == 0">No server found.</p>
            </div>
          </div>
          <Toast /><ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerServerFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button v-show="newCustomerServer.server_id" type="button" @click="resetForm()" class="btn float-right btn-warning btn-circle btn-sm"><i class="fa fa-times" aria-hidden="true"></i></button>
              </div>
            </div>
            <p class="card-text"></p>

            <!-- <label for="">Server Name</label> -->
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="">
                  <select v-model="newCustomerServer.server_id" @change="onSelect(newCustomerServer.server_id)" class="form-control form-control-sm">
                    <option selected :value="undefined">--Select Server--</option>
                    <option :value="server.id" v-for="server in servers" :key="server">
                      {{ server.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <input type="text" v-model="newCustomerServer.ip_address" readonly placeholder="IP address" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <input type="text" v-model="newCustomerServer.domain_name" placeholder="Domain name (example.com)" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <input type="text" v-model="newCustomerServer.repo" placeholder="Repository link" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <input type="text" v-model="newCustomerServer.doc_root" placeholder="Document root" class="form-control form-control-sm">
              </div>  
              <div class="col-md-4 mb-3">
                <input type="text" v-model="newCustomerServer.db_name" placeholder="DB Name" class="form-control form-control-sm">
              </div>
              <div class="col-md-4 mb-3">
                <input type="text" v-model="newCustomerServer.db_user" placeholder="DB User" class="form-control form-control-sm">
              </div>
              <div class="col-md-4 mb-3">
                <input type="text" v-model="newCustomerServer.db_password" placeholder="DB Password" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <input type="text" v-model="newCustomerServer.php_my_admin" placeholder="Phpmyadmin" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <!-- <label for="">Notes</label> -->
                <textarea
                  placeholder="Note"
                  v-model="newCustomerServer.notes"
                  class="form-control"
                  cols="20"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <button :disabled="!newCustomerServer.server_id" class="btn btn-primary btn-user btn-block">{{BtnTitle}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import sendEmail from './sendEmail.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import moment from "moment";
import axios from "axios";
import ProgressSpinner from 'primevue/progressspinner';
export default {
  components: {
    sendEmail, 
    Dialog, 
    DataTable, 
    Column, 
    ConfirmDialog, 
    Toast,
    ProgressSpinner,
  },
  data() {
    return {
      newCustomerServer: {},
      customerProducts:[],
      customerServers:[],
      emailsHistory:[],
      moment: moment,
      servers: [],
      serverDetail: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customerServerFormTitle: "Add Server",
      disc_text:true,
      customer_id:null,
      display: false,
      server_id: null,
      loading: true,
      historyDisplay: false,
      BtnTitle: "Create",
    }
  },
  created() {
    this.getCustomerServers();
    this.loadServers();
  },
  methods: {

    dialog(title)
    {
      this.display=title;
    },
    
    loadServers: function () {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/servers', {headers})
      .then(response => {
        console.log("All Servers from Database -->", response.data)
        // commit('loadServers', response.data);
        this.servers = response.data;
      })
    },

    onSelect(serverID) {
      this.getServer(serverID);
    },

    getServer: function (id) {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/servers/' + id, {headers})
      .then(response => {
        this.serverDetail = response.data;
        this.newCustomerServer.ip_address = this.serverDetail.server_ip;
      })
    },

    showDialog: function (server) {
      
      this.server_id = server;
      this.display = true;
    },

    historyModel: function () {
      this.historyDisplay = true;
    },

    getCustomerServers: function () {
      this.loading = true;
      this.customer_id = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

      // Get all Servers of this Customer
        axios.get(this.env_api_url + '/customers/'+this.customer_id, {headers})
        .then((response) => {
          this.loading = false;
          console.log("All Server of this customer --> ",response);
          this.customerProducts = response.data.customer_products;
          this.customerServers = response.data.customer_servers;
          this.emailsHistory = response.data.emails_history;
          
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
      // Get all Servers of this Customer
    },

    formSubmit() {
      var data={
        'id':this.newCustomerServer.id,
        'ip_address':this.newCustomerServer.ip_address,
        'domain_name':this.newCustomerServer.domain_name,
        'repo':this.newCustomerServer.repo,
        'doc_root':this.newCustomerServer.doc_root,
        'db_name':this.newCustomerServer.db_name,
        'db_user':this.newCustomerServer.db_user,
        'db_password':this.newCustomerServer.db_password,
        'php_my_admin':this.newCustomerServer.php_my_admin,
        'notes':this.newCustomerServer.notes,
        'serverable_id':this.$route.params.id,
        'serverable_type':"App/Models/User",
        'server_id':this.newCustomerServer.server_id,
      }

      this.BtnTitle = "Waiting...";

      // Products store
        var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
        axios.post(this.env_api_url + '/customer_servers', data,{headers})
        .then((response) => {
          console.log("Server store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Save Successfully!",
          });
          this.BtnTitle = "Create";
          this.resetForm();
          this.getCustomerServers();
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
      // Products store
    },
    editCustomerServer(customerServer) {
      this.newCustomerServer = customerServer;
      this.customerServerFormTitle = "Edit Server";
      this.BtnTitle = "Update";
    },
    deleteCustomerServer(customerServer) {

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"), };

      this.$confirm.require({
        message: 'Do you want to delete ' + customerServer.domain_name + '?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios.delete(this.env_api_url + "/customer_servers/" + customerServer.id, {
              headers: headers,
            })
          .then((response) => {
            this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
            console.log("Delete customer basic data: ", response);
            this.getCustomerServers();
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
    resetForm() {
      this.customerServerFormTitle = "Add Server";
      this.newCustomerServer = {};
    },
    showPasword() {
      this.disc_text = !this.disc_text; //Disc text class will fail
    },

  },
};
</script>

<style scoped>
  .text_As_Pswrd {
    -webkit-text-security: disc;
  }
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    color:#dfc04e;
    transform: scale(1.5);
  }
</style>