<template>
  <page-header :title="'New Checklist Item'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">

      <form @submit.prevent="formSubmit" class="user">
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                 
                  <!-- List Type Field -->
                  <label class="required">Select List Type</label>
                  <select class="form-control form-control-sm" v-model="listType">
                    <option selected :value="null">-- Select Type --</option>
                    <option :value="2">Developer Checkoff list</option>
                    <option :value="1">Staff Checkoff list</option>
                  </select>
                  <br><br>
                  <small class="text-danger mt-3" v-if="listTypeError">
                    {{ listTypeError }}
                  </small>
                </div>

                <!-- Select Product Field -->
                <div class="col-md-6" v-if="listType == 2">
                  <label class="required">Select Product</label>
                  <select class="form-control form-control-sm" v-model="productId">
                    <option selected :value="null">-- Select Product --</option>
                    <option selected :value="product.id" v-for="product in products" :key="product">
                      {{ product.name }}
                    </option>
                  </select>
                  <br><br>
                  <small class="text-danger mt-3" v-if="productIdError">
                    {{ productIdError }}
                  </small>
                </div>
              </div>
            </div>
            
            <!-- One or more title fields -->
            <div class="row mt-2 ml-1 mr-1" v-for="(item,index) in checkilst_fields" :key="item">
              <div class="col-md-12 p-1">
                <label class="required">Title</label>
                <div class="input-group mb-2">
                  <input type="text" class="form-control form-control-sm" v-model="item.item_title" placeholder="Enter checklist title here">
                  <div class="input-group-append" v-if="checkilst_fields.length > 1">
                    <button class="btn btn-outline-danger btn-sm" type="button" @click="removeInputRow(checkilst_fields, index)">Delete</button>
                  </div>
                </div>
                <small class="text-danger" v-if="itemTitleErrors[index]">
                {{ itemTitleErrors[index] }}
              </small>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary ml-2 mt-3 btn-sm" :disabled="btnDisabled">{{ btnTitle }}</button>
            <button type="button" class="btn btn-sm mr-2 btn-success mt-3 ml-2 float-right" :disabled="btnDisabled" @click="addMorePetitioner()">Add more Item</button>
          </div>
        </div>
      </form>

      <Toast />
      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import ConfirmDialog from 'primevue/confirmdialog';
import axios from "axios";

export default {
  components: {
    PageHeader, ConfirmDialog
  },

  data() {
    return {
      products: [],
      btnDisabled: false,
      btnTitle: "Create",
      base_url: process.env.VUE_APP_SERVICE_URL,

      productId: null,
      listType: null,
      checkilst_fields: [{ item_title: "" }],

      productIdError: null,
      listTypeError: null,
      itemTitleErrors: []
    };
  },

  created() {
    this.getProducts();
  },

  methods: {

    validateForm() {
      let isValid = true;
      this.listTypeError = null;
      this.productIdError = null;
      this.itemTitleErrors = [];

      // Validate listType
      if (!this.listType) {
        this.listTypeError = "Please select list type.";
        isValid = false;
      }
      
      // Validate Product
      if (!this.productId && this.listType == 2) {
        this.productIdError = "Please select any product.";
        isValid = false;
      }

      this.checkilst_fields.forEach((field, index) => {
        if (!field.item_title.trim()) {
          this.itemTitleErrors[index] = "Checklist title is required.";
          isValid = false;
        } else {
          this.itemTitleErrors[index] = null;
        }
      });

      return isValid;
    },

    addMorePetitioner() {
      this.checkilst_fields.push({ item_title: "" });
      this.itemTitleErrors.push(null); // Add a placeholder for the new item
    },

    removeInputRow: function (obj, index) {
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          obj.splice(index, 1);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Item has been successfully deleted.",
          });
        },
        reject: () => {
          this.$notify({
            type: "info",
            title: "Cancelled",
            text: "Your record is safe!",
          });
        }
      });
    },

    getProducts: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/allProducts', { 
          headers
        })
        .then((response) => {
          this.products = response.data.products;
          this.totalRows = 0;
          this.loading = false;
        });
    },

    formSubmit() {
      if (this.validateForm()) {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          list_type: this.listType,
          product_id: this.productId,
          checkilst_fields: this.checkilst_fields
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios.post(this.base_url + "/all-items", data, { headers }).then(
          (response) => {
            console.log(response);
            this.btnDisabled = false;
            this.btnTitle = "Create";
            this.$notify({
              type: "success",
              title: "Success",
              text: "New checklist added successfully!",
            });
            this.$router.push({ path: "/checklist" });
          },
          (error) => {

            this.btnDisabled = false;
            this.btnTitle = "Create";

            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
        

      } else {

        console.log("errors");
        this.$notify({
          type: "error",
          title: "Invalid Form Data",
          text: 'Please fill required fields',
        });

      }
    },
  },
};
</script>