<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Products</h4>
          <p class="card-text"></p>
          <div class="d-flex flex-column justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px; margin-top:1px;" strokeWidth="1"
              fill="var(--surface-ground)" animationDuration=".5s" />
          </div>
          <div class="row" v-else>
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="customerProduct in customerProducts" :key="customerProduct">
                <div class="card-body">
                  <p class="card-title">
                    Product: {{ customerProduct.product.name }}
                    <span class="float-right"><button @click="editCustomerProduct(customerProduct)"
                        class="btn btn-circle btn-primary btn-sm mr-1">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteCustomerProduct(customerProduct)">
                        <i class="fas fa-trash"></i></button></span>
                  </p>
                  <p class="card-text" v-if="customerProduct.notes">
                    Notes: <small> {{ customerProduct.notes }}</small>
                  </p>
                  <hr>
                  <CustomerCheckoffList 
                    v-if="customerProducts.length > 0" 
                    :customerProduct="customerProduct"
                    :staff_checkoff_list_count="staff_checkoff_list_count"
                    :staffStatus="staffStatus(customerProduct)"
                    :devStatus="devStatus(customerProduct)"
                    @refreshCustomerApi="refreshApi()"
                  />
                </div>
              </div>
              <p class="text-danger text-center" v-if="customerProducts.length == 0">No product found.</p>
            </div>
          </div>

          <Toast />
          <ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerProductFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button v-show="newCustomerProduct.product_id" type="button" @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="">Product Name</label>
                  <select v-model="newCustomerProduct.product_id" class="form-control" name="" id="">
                    <option selected :value="undefined">--Product--</option>
                    <option :value="product.id" v-for="product in products" :key="product">
                      {{ product.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="">Notes</label>
                  <textarea v-model="newCustomerProduct.notes" class="form-control" name="" id="" cols="30"
                    rows="5"></textarea>
                </div>
              </div>
            </div>
            <button :disabled="!newCustomerProduct.product_id" class="btn btn-primary btn-user btn-block">
              {{ BtnTitle }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import CustomerCheckoffList from './components/CustomerCheckoffList.vue';
export default {
  data() {
    return {
      newCustomerProduct: {},
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customerProductFormTitle: "Add Product",
      customerProducts: [],
      loading: true,
      products: [],
      BtnTitle: "Create",
      staff_checkoff_list_count: 0,
    };
  },
  components: {
    ConfirmDialog,
    Toast,
    ProgressSpinner,
    CustomerCheckoffList
  },
  created() {
    this.getCustomerProducts();
    this.loadProducts();
  },
  methods: {
    loadProducts: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/product', { headers })
        .then(response => {
          console.log("All products from Database -->", response.data.products)
          this.loading = false;
          this.products = response.data.products;
        })
    },

    getCustomerProducts: function () {
      this.loading = true;
      var customerId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Products
      ///For Customer products called Eloquent from Customer Controller
      axios
        .get(this.env_api_url + "/customers/" + customerId, { headers })
        .then((response) => {
          console.log(
            "All Products of this customer --> ",
            response.data.customer_products
          );
          this.loading = false;
          this.customerProducts = response.data.customer_products;
          this.staff_checkoff_list_count = response.data.staff_checkoff_list_count;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      // Get all Products
    },

    formSubmit() {
      var data = {
        id: this.newCustomerProduct.id,
        product_id: this.newCustomerProduct.product_id,
        user_id: this.$route.params.id,
        notes: this.newCustomerProduct.notes,
      };

      this.BtnTitle = "Waiting...";
      // Products store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/customer_products", data, { headers })
        .then((response) => {
          console.log("Product store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.BtnTitle = "Create";
          this.resetForm();
          this.getCustomerProducts();
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
      // Products store
    },

    editCustomerProduct(customerProduct) {
      this.newCustomerProduct = customerProduct;
      this.customerProductFormTitle = "Edit Product";
      this.BtnTitle = "Update";
    },
    deleteCustomerProduct(customerProduct) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: "Do you want to delete " + customerProduct.product.name + "?",
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(
              this.env_api_url + "/customer_products/" + customerProduct.id,
              {
                headers: headers,
              }
            )
            .then((response) => {
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
              console.log("Delete customer basic data: ", response);
              this.getCustomerProducts();
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },
    resetForm() {
      this.customerProductFormTitle = "Add Product";
      this.newCustomerProduct = {};
    },

    refreshApi() {
      this.getCustomerProducts();
    },
    
    staffStatus(customerProduct) {
      if (customerProduct.selected_staff_checkoff_count > 0 && customerProduct.selected_staff_checkoff_count == this.staff_checkoff_list_count) {
        return 'Completed';
      }
      else if (customerProduct.selected_staff_checkoff_count > 0 && customerProduct.selected_staff_checkoff_count != this.staff_checkoff_list_count) {
        return 'In-progress';
      }
      else {
        return 'Pending';
      }
    },
    
    devStatus(customerProduct) {
      if (customerProduct.selected_developer_checkoff_count > 0 && customerProduct.selected_developer_checkoff_count == customerProduct.developer_checkoff_count) {
        return 'Completed';
      }
      else if (customerProduct.selected_developer_checkoff_count > 0 && customerProduct.selected_developer_checkoff_count != customerProduct.developer_checkoff_list_count) {
        return 'In-progress';
      }
      else {
        return 'Pending';
      }
    },
  },
};
</script>

<style>

</style>