<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Notes</h4>
          <p class="card-text"></p>

          <div class="d-flex flex-column justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px; margin-top:1px;" strokeWidth="1"
              fill="var(--surface-ground)" animationDuration=".5s" />
          </div>
          <div class="row" v-else>
            <div class="col-sm-12">
              <div
                class="card mt-2 mt-4"
                v-for="customerNote in customerNotes"
                :key="customerNote"
              >
                <div class="card-body">
                  <p class="card-title" style="white-space: pre-line;">
                    Note: {{ customerNote.notes }}
                    <span class="float-right"
                      ><button
                        @click="editCustomerNotes(customerNote)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteCustomerNotes(customerNote)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p class="card-text" v-if="customerNotes.notes">
                    Notes: <small> {{ customerNotes.notes }}</small>
                  </p>
                </div>
              </div>
              <p class="text-danger text-center" v-if="customerNotes.length == 0 || customerNotes == null">No note found.</p>
            </div>
          </div>
          <Toast /><ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>

    <div class="col-md-6 mb-4">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerNoteFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newCustomerNote.notes"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-12 ">
                <div class="mb-3">
                  <label for="">Notes</label>
                  <textarea
                    v-model="newCustomerNote.notes"
                    class="form-control"
                    name=""
                    id=""
                    cols="30"
                    rows="6"
                    ref='notes'
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              :disabled="!newCustomerNote.notes"
              class="btn btn-primary btn-user btn-block"
            >
            {{BtnTitle}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  computed: mapState(["user"]),
  components: {
    ConfirmDialog, 
    Toast,
    ProgressSpinner
  },
  data() {
    return {
      newCustomerNote: {},
      moment: moment,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customerNoteFormTitle: "Add Notes",
      customerNotes: [],
      loading: true,
      BtnTitle: "Create",
    };
  },

  mounted(){
    this.$refs.notes.focus();
  },
  created() {
    this.getcustomerNotes();
  },
  methods: {
    getcustomerNotes: function () {
      this.loading = true;
      var customerId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Products
      ///For Customer products called Eloquent from Customer Controller
      axios
        .get(this.env_api_url + "/customers/" + customerId, { headers })
        .then((response) => {
          console.log( "All Notes of this customer --> ", response.data.customer_notes );
          this.loading = false;
          this.customerNotes = response.data.customer_notes;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      // Get all Products
    },

    formSubmit() {
      var data = {
        id: this.newCustomerNote.id,
        user_id: this.$route.params.id,
        notes: this.newCustomerNote.notes,
      };

      this.BtnTitle = "Waiting...";
      // Products store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/customer_notes", data, { headers })
        .then((response) => {
          console.log("Notes store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.BtnTitle = "Create";
          this.resetForm();
          this.getcustomerNotes();
        })
        .catch((error) => {
            this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
            });
            console.warn("Your Error is :", error);
          });
      // Products store
    },

    editCustomerNotes(customerNote) {
      this.newCustomerNote = customerNote;
      this.customerNoteFormTitle = "Edit Notes";
      this.BtnTitle = "Update";
    },
    deleteCustomerNotes(customerNote) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      this.$confirm.require({
        message: "Do you want to delete " + customerNote.notes + "?",
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
          .delete(
            this.env_api_url + "/customer_notes/" + customerNote.id,
            {
              headers: headers,
            }
          )
          .then((response) => {
            
            this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
            
            console.log("Delete customer basic data: ", response);
            this.getcustomerNotes();
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
            
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
    resetForm() {
      this.customerNoteFormTitle = "Add Notes";
      this.newCustomerNote = {};
    },
  },
};
</script>

<style>
</style>