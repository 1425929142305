<template>
  <div class="justify-content-center">
    <div
      class="card o-hidden border-0 shadow-lg my-5"
      style="border-radius: 0px"
    >
      <div class="card-body pt-0 pl-5 pr-5">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-5 mb-5">
            <div v-if="this.loading" class="text-center mt-5 mb-5">
              <br><br>
              <ProgressSpinner style="width: 150px; height: 150px" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".9s" aria-label="Custom ProgressSpinner" />
              <br><br><br>
            </div>
            <div class="p-5" v-else-if = !token_used>
              <p class="h4 pull-left text-primary mb-3">
                <strong>Developer Checklist <small class="text-success">(Product: {{ this.productName }})</small></strong>
              </p>

              <form @submit.prevent="formSubmit">
                
                <div class="form-group">
                  <label class="required" for="exampleCompanyName">Developer Name</label>
                  <input
                    v-model="developerName"
                    type="text"
                    class="form-control form-control-sm w-25"
                    :class="{ 'is-invalid': nameError }"
                    autocomplete="chrome-off"
                  />
                  <div v-if="nameError" class="invalid-feedback">
                    Name is required.
                  </div>
                </div>
                
                <table class="table table-hover">
                  <thead class="bg-secondary">
                    <tr class="text-white">
                      <th scope="col">List Items</th>
                      <th scope="col" class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="listItem in checklistsData"
                      :key="listItem.id"
                      @click="toggleSelection(listItem.id)"
                    >
                      <td>{{ listItem.title }}</td>
                      <td class="p-0 text-center">
                        <Checkbox class="mt-2" v-model="selected_checklist_items" :inputId="listItem.id" :value="listItem.id" @click.stop="toggleSelection(listItem.id)" />
                      </td>
                    </tr>

                    <!-- <tr v-if="checklistsData.length > 0">
                      <td colspan="2">
                        <br /><strong class="text-danger"
                          >No checkoff list item found.</strong
                        >
                      </td>
                    </tr> -->
                  </tbody>
                </table>

                <div class="row">
                  <div class="col-md-2">
                    <button class="btn btn-block" :class="selected_checklist_items.length == 0 ? 'btn-danger' : 'btn-success'" :disabled="selected_checklist_items.length == 0 || this.btnDisabled" > {{ this.btnDisabled ? 'Please wait...' : 'Submit' }}</button>
                  </div>
                  <div class="col-md-6">
                    <p class="text-danger mt-2" v-if="selected_checklist_items.length == 0">Please select at least one checklist item.</p>
                  </div>
                  <div class="col-md-4">
                    <strong class="text-secondary">
                      Total Selected Items: <span :class="totalClass">{{ selected_checklist_items.length }} / {{ checklistsData.length }}</span> 
                      <small class="ml-2 badge badge-pill badge-warning" v-if="totalClass == 'text-danger'">Pending</small>
                      <small class="ml-2 badge badge-pill badge-primary" v-if="totalClass == 'text-info'">In-progress</small>
                      <small class="ml-2 badge badge-pill badge-success" v-if="totalClass == 'text-success'">Completed</small>
                    </strong> 
                  </div>
                </div>
              </form>
            </div>
            <div v-else class="text-center">
              <br><br><br><br><br><br>
              <h3>404 - Page Not Found</h3>
              <small>We’re sorry, but the page you’re looking for doesn’t exist </small>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';

export default {

  components: {
    Checkbox,
    ProgressSpinner
  },

  data() {
    return {
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      selected_checklist_items: [],
      btnDisabled: false,
      loading: true,
      checklistsData: null,
      productName: null,
      checkoffListType: null,
      developerName: null,
      listStatus: "Pending",
      nameError: false,
      token_used: false,

    };
  },

  computed: {
    totalClass() { 
      if (this.selected_checklist_items.length === this.checklistsData.length && this.checklistsData.length > 0) {
        return 'text-success';
      } else if (this.selected_checklist_items.length > 0 && this.selected_checklist_items.length != this.checklistsData.length) {
        return 'text-info';
      } else if (this.selected_checklist_items.length == 0) {
        return 'text-danger';
      }
      return ''; // Default class if no conditions match
    },
  },

  created() {

    this.getChecklistItems();
  },

  methods: {
    toggleSelection(itemId) {
      const index = this.selected_checklist_items.indexOf(itemId);
      if (index === -1) {
        // Add item if not already selected
        this.selected_checklist_items.push(itemId);
      } else {
        // Remove item if already selected
        this.selected_checklist_items.splice(index, 1);
      }
    },

    getChecklistItems() {
      this.loading = true;
      const data = {
        token: this.$route.query.token,
        product_id: this.$route.query.system
      };

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
        .post(this.env_api_url + "/dev-checkoff-list", data, { headers })
        .then((response) => {
          this.checklistsData = response.data.checklists;
          this.selected_checklist_items = response.data.product_selected_checklist_array;
          this.productName = response.data.product_name;
          this.loading = false;
          this.token_used = response.data.token_used;
          this.checkoffListType = 2;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },

    formSubmit() {
      this.nameError = false;

      if (!this.developerName) {
        this.nameError = true;
        return;
      } else {

        this.btnDisabled = true;
        const data = {
          list_type: 2, //For dev checklist
          developer_name: this.developerName,
          user_product_id: this.$route.query.owner,
          token: this.$route.query.token,
          selected_items: this.selected_checklist_items
        };

        const headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
        axios.post(this.env_api_url + "/submit_checklist", data, { headers })
        .then((response) => {
          console.log("Checklist submitted successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Checklist saved successfully!",
          });
          this.btnDisabled = false;
          this.$router.push({ path: "/checklist/submitted" });
        })
        .catch((error) => {
          console.error("Error submitting checklist:", error);
          this.btnDisabled = false;
          this.$notify({
            type: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        });
      }
    }
  },
};
</script>
<style scope>
.question-card {
  padding: 10px;
  box-shadow: 3px 4px 11px 0px rgb(0 0 0 / 20%);
  border-radius: 7px;
}

body {
  font-size: 15px;
}
</style>
