<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-5">
          <table-header
            :title="'Checklists Items'"
            :hasLink="true"
            :linkTo="'/checklist/create'"
            :linkText="'New Item'"
          />
        </div>
        <div class="col-md-7">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input
                v-model="filters.title"
                class="form-control form-control-sm"
                placeholder="Search by title"
                ref="search"
              />
            </div>
            <div class="form-group">
              <div>
                <select v-model="filters.product_id" class="ml-1 form-control form-control-sm">
                <option selected :value="undefined">-- Select Product --</option>
                <option selected :value="product.id" v-for="product in products" :key="product">
                  {{ product.name }}
                </option>
                </select>
              </div>
            </div>
            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="this.filters={}">Reset</button>
            </div>
          </form>
          <DownloadButton :url="exportUrl" :fileName="ExcelFileName" class="float-right ml-5 mr-1" />
        </div>
      </div>
    </div>

    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="checklists" :paginator="true" style="padding:4px;" :rows="loadRows" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="title" header="Title" :sortable="true"></Column>

        <Column style="padding:4px;" header="List type">
          <template #body="slotProducts">
            <span v-if="slotProducts.data.product">
              <small class="badge badge-success mr-1">Developer - {{ slotProducts.data.product.name }}</small>
            </span>

            <span v-else>
              <small class="badge badge-info">Staff</small>
            </span>
          </template>
        </Column>

        <Column style="padding:4px;" header="Status" >
          <template #body="slotDate">  
            <small class="badge badge-primary mr-1" :class="{ 'badge-danger': !slotDate.data.active }">{{slotDate.data.active?'Active':'Inactive'}}</small>
          </template>
        </Column>

        <Column style="padding:4px;" field="display_order" header="Display order" :sortable="true">
          <template #body="slotDate">
            <label class="pl-5">{{slotDate.data.display_order}}</label>
          </template>
        </Column>

        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>

        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <router-link :to="{name:'EditItem', params: { id:slotProps.data.id}}" ><i class="fas fa-edit btn btn-circle btn-primary  btn-sm"></i></router-link >
              <button v-on:click="deleteChecklist(slotProps.data.id)" class="btn btn-circle btn-danger  btn-sm ml-1"><i class="fas fa-trash"></i></button>
            </template>
        </Column>
      </DataTable>
    </div>
    <Toast /><ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
export default {

  components: {
    TableHeader, 
    DataTable, 
    Column,
    ProgressSpinner,
    ConfirmDialog,
    Toast
  },

  data() {
    return {
      filters:{},
      loading: true,
      loadRows: 10,
      checklists: [],
      products: [],
      moment: moment,
      ProductID: null,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      base_url:process.env.VUE_APP_SERVICE_URL
    };
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getChecklists()
            this.loading=true;
            this.awaitingSearch = false;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },
  
  methods: {
    getProducts: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/allProducts', { 
          headers
        })
        .then((response) => {
          this.products = response.data.products;
          this.totalRows = 0;
          this.loading = false;
        });
    },

    getChecklists: function () {
      this.filters.type = this.$route.query.type?this.$route.query.type:"";

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/all-items', { 
          headers,
          params: this.filters
        })
        .then((response) => {
          this.checklists = response.data.checklists;
          this.totalRows = 0;
          this.loading = false;
        });
    },

    deleteChecklist: function (ProductID) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(this.env_api_url + "/all-items/" + ProductID, {
              headers: headers
            })
            .then((response) => {
              this.getChecklists();
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Product Deleted: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
  },
  created() {
    this.getChecklists();
    this.getProducts();
  },
};
</script>