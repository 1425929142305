<template>
  <page-header :title="'Edit Checklist Item'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">

      <div class="d-flex flex-column justify-content-center" v-if="loading">
          <ProgressSpinner style="width:50px;height:50px; margin-top:20px;" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s" />
      </div>
      <form @submit.prevent="formSubmit" class="user" v-else>
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                 
                  <!-- List Type Field -->
                  <label class="required">Select List Type</label>
                  <select class="form-control form-control-sm" v-model="listType">
                    <option selected :value="null">-- Select Type --</option>
                    <option :value="2">Developer Checkoff list</option>
                    <option :value="1">Staff Checkoff list</option>
                  </select>
                  <br><br>
                  <small class="text-danger mt-3" v-if="listTypeError">
                    {{ listTypeError }}
                  </small>
                </div>

                <!-- Select Product Field -->
                <div class="col-md-6" v-if="listType == 2">
                  <label class="required">Select Product</label>
                  <select class="form-control form-control-sm" v-model="productId">
                    <option selected :value="null">-- Select Product --</option>
                    <option selected :value="product.id" v-for="product in products" :key="product">
                      {{ product.name }}
                    </option>
                  </select>
                  <br><br>
                  <small class="text-danger mt-3" v-if="productIdError">
                    {{ productIdError }}
                  </small>
                </div>
              </div>
            </div>
            
            <!-- One or more title fields -->
            <div class="row mt-2 ml-1 mr-1">
              <div class="col-md-12 p-1">
                <label class="required">Title</label>
                <div class="input-group mb-2">
                  <input type="text" class="form-control form-control-sm" v-model="itemTitle" placeholder="Edit checklist title">
                </div>
                <small class="text-danger mt-3" v-if="itemTitleError">
                  {{ itemTitleError }}
                </small>
              </div>
            </div>
            
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary ml-2 mt-3 btn-sm" :disabled="btnDisabled">{{ btnTitle }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import ProgressSpinner from 'primevue/progressspinner';
import axios from "axios";

export default {
  components: {
    PageHeader, 
    ProgressSpinner
  },

  data() {
    return {
      loading: true,
      
      products: [],
      btnDisabled: false,
      btnTitle: "Update",
      base_url: process.env.VUE_APP_SERVICE_URL,

      productId: null,
      listType: null,
      itemTitle: null,

      productIdError: null,
      listTypeError: null,
      itemTitleError: null
    };
  },

  created() {
    this.getProducts();
    this.getEditData();
  },

  methods: {

    validateForm() {
      let isValid = true;
      this.listTypeError = null;
      this.productIdError = null;
      this.itemTitleError = null;

      // Validate listType
      if (!this.listType) {
        this.listTypeError = "Please select list type.";
        isValid = false;
      }
      
      // Validate Product
      if (!this.productId && this.listType == 2) {
        this.productIdError = "Please select any product.";
        isValid = false;
      }
      
      // Validate Item title
      if (!this.itemTitle) {
        this.itemTitleError = "Please select any product.";
        isValid = false;
      }

      return isValid;
    },

    getEditData: function () {
      var id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url + '/all-items/' +  id, { 
          headers
        })
        .then((response) => {
          this.itemTitle = response.data.product_checklist.title;
          this.listType = response.data.product_checklist.list_type;
          this.productId = response.data.product_checklist.product_id;
          this.loading = false;
        });
    },

    getProducts: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/allProducts', { 
          headers
        })
        .then((response) => {
          this.products = response.data.products;
          this.totalRows = 0;
          this.loading = false;
        });
    },

    formSubmit() {
      if (this.validateForm()) {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          title: this.itemTitle,
          list_type: this.listType,
          product_id: this.productId,
        };

        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        var id = this.$route.params.id;

        axios.put(this.base_url + "/all-items/" + id, data, { headers }).then(
          (response) => {
            console.log(response);
            this.btnDisabled = false;
            this.btnTitle = "Update";
            this.$notify({
              type: "success",
              title: "Success",
              text: "Checklist Item updated successfully!",
            });
            this.$router.push({ path: "/checklist" });
          },
          (error) => {
            this.btnDisabled = false;
            this.btnTitle = "Update";
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );

      } else {
        console.log("errors");
        this.$notify({
          type: "error",
          title: "Invalid Form Data",
          text: 'Please fill required fields',
        });
      }
    }
  },
};
</script>